import React from "react"
import SEO from "../components/seo"

export default function Home() {
  return (
    <div className="flex bg-black text-white h-screen items-center justify-center">
      
      <div>
        <SEO title="Welcome"/>
        <h1 className="text-3xl">Papercones Inc.</h1>
      </div>
    </div>
  )
}
